import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { phoneRegExp } from "../../../utils/functions";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import Dropzone from "react-dropzone";
import ReCAPTCHA from "react-google-recaptcha";
import { mailingList } from "../../../utils/vars";

export default function CareerForm() {
  const reCaptchaRef = useRef<ReCAPTCHA | null>(null);
  const [uploadedAttachments, setUploadedAttachments] = useState<File[]>([]);
  const [touchedAttachments, setTouchedAttachments] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const verifyRecaptcha = async () => {
    if (!reCaptchaRef.current) return null;
    try {
      return await reCaptchaRef.current.executeAsync();
    } catch (error) {
      return null;
    }
  };

  const signupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Enter your name").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Required"),
    position: Yup.string()
      .min(5, "Enter the position you're interested in")
      .required("Required"),
    attachments: Yup.array()
      .of(Yup.mixed())
      .min(1, "Required")
      .test(
        "fileSize",
        "9 MB maximum for file uploads",
        function (files: File[] | undefined) {
          let totalSize = 0;
          files?.forEach((file) => (totalSize += file.size));
          return totalSize < 9 * 1024 * 1024;
        }
      ),
    recaptchaToken: Yup.string().when("$isSubmitting", {
      is: true,
      then: Yup.string().required("ReCAPTCHA verification failed"),
      otherwise: Yup.string(),
    }),
  });

  return (
    <div className="careerComponents__form">
      <div className="careerComponents__formHeader">
        <span>
          If you're interested in joining the Robotic Imaging team, fill in your
          information and upload your resume. We'll get back to you ASAP.
        </span>
      </div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          phone: "",
          position: "",
          attachments: [] as File[],
          recaptchaToken: "",
          success: false,
        }}
        validationSchema={signupSchema}
        onSubmit={async (values, { setFieldValue, resetForm }) => {
          setIsSubmitting(true);
          const token = await verifyRecaptcha();

          if (!token) {
            setFieldValue("recaptchaToken", "");
            setIsSubmitting(false);
            return;
          }

          try {
            const verificationResponse = await axios.post(
              `${process.env.GATSBY_firebase_function_domain}/recaptcha-verifyRecaptcha`,
              { token }
            );

            if (
              !verificationResponse.data.success ||
              verificationResponse.data.score < 0.5
            ) {
              console.error("Verification failed:", verificationResponse.data);
              return;
            }

            const formData = new FormData();
            formData.append("destination", values.email);
            formData.append("bcc", JSON.stringify(mailingList));
            formData.append("subject", "Robotic Imaging Career Application");
            values.attachments.forEach((file: File) => {
              formData.append("attachments", file, file.name);
            });
            formData.append(
              "text",
              `
              Thanks for reaching out to Robotic Imaging! We'll get back to you with a follow-up and more information as soon as possible!

              Here's a copy of the information you've sent to us:
              Name: ${values.name}
              Email: ${values.email}
              Phone: ${values.phone}
              Desired Position: ${values.position}
            `
            );
            formData.append(
              "html",
              `
              <div>Thanks for reaching out to Robotic Imaging! We'll get back to you with a follow-up and more information as soon as possible!</div>
              <br />
              <div>Here's a copy of the information you've sent to us:</div>
              <div>Name: ${values.name}</div>
              <div>Email: ${values.email}</div>
              <div>Phone: ${values.phone}</div>
              <div>Desired Position: ${values.position}</div>
            `
            );

            axios.post(
              `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMailWithAttachmentsV2`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            setFieldValue("success", true);
            setTimeout(() => {
              resetForm();
              setUploadedAttachments([]);
              setTouchedAttachments(false);
              setIsSubmitting(false);
            }, 10000);
          } catch (error) {
            console.error(error);
            setIsSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, setFieldValue }) => {
          const attachmentErrors =
            typeof errors.attachments === "string"
              ? errors.attachments
              : Array.isArray(errors.attachments)
              ? errors.attachments.map((err) => err.toString()).join(", ")
              : "";

          return (
            <Form
              className="career-request-form"
              encType="multipart/form-data"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div className="career-request-form_left-column">
                <div className="career-request-form__input-row">
                  <label htmlFor="name" className="career-request-form__label">
                    Name
                  </label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="John Smith"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.name && touched.name ? (
                    <div className="requestForm__errors">{errors.name}</div>
                  ) : null}
                </div>

                <div className="career-request-form__input-row">
                  <label htmlFor="email" className="career-request-form__label">
                    Email
                  </label>
                  <Field
                    name="email"
                    type="text"
                    placeholder="email@address.com"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.email && touched.email ? (
                    <div className="requestForm__errors">{errors.email}</div>
                  ) : null}
                </div>

                <div className="career-request-form__input-row">
                  <label htmlFor="phone" className="career-request-form__label">
                    Phone
                  </label>
                  <Field
                    name="phone"
                    type="text"
                    placeholder="123-456-7890"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.phone && touched.phone ? (
                    <div className="requestForm__errors">{errors.phone}</div>
                  ) : null}
                </div>
                <div className="career-request-form__input-row">
                  <label
                    htmlFor="position"
                    className="career-request-form__label"
                  >
                    Desired Position
                  </label>
                  <Field
                    name="position"
                    type="text"
                    placeholder="Reality Capture Technician"
                    style={{ border: "1px solid black", borderRadius: "10px" }}
                    className="career-request-form__text-input"
                  />
                  {errors.position && touched.position ? (
                    <div className="requestForm__errors">{errors.position}</div>
                  ) : null}
                </div>
              </div>
              <div className="career-request-form_left-column">
                <div className="career-request-form__input-row">
                  <label
                    htmlFor="attachments"
                    className="career-request-form__label__attachment-label"
                  >
                    Attachments
                  </label>
                  <Dropzone
                    onDrop={(files: File[]) => {
                      setFieldValue("attachments", [...files]);
                      setUploadedAttachments(files);
                      setTouchedAttachments(true);
                    }}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        {...getRootProps()}
                        className={`dropzone${
                          isDragActive ? " dropzone-active" : ""
                        }`}
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          padding: "20px",
                          border: uploadedAttachments.length
                            ? "2px solid #4CAF50"
                            : "2px dashed #ccc",
                          backgroundColor: uploadedAttachments.length
                            ? "#E8F5E9"
                            : "#fff",
                          transition: "all 0.3s ease",
                        }}
                      >
                        <input {...getInputProps()} />
                        {!uploadedAttachments.length ? (
                          <div style={{ textAlign: "center" }}>
                            <span style={{ fontSize: "14px", color: "000" }}>
                              Click or drag and drop your files here...
                            </span>
                          </div>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <span
                              style={{ color: "#2E7D32", fontWeight: "500" }}
                            >
                              ✓ {uploadedAttachments[0].name}
                              {uploadedAttachments.length > 1 &&
                                ` and ${
                                  uploadedAttachments.length - 1
                                } other file(s)`}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </Dropzone>
                  {errors.attachments && touchedAttachments ? (
                    <div className="requestForm__errors">
                      {attachmentErrors}
                    </div>
                  ) : null}
                </div>
                {values.success ? (
                  <div style={{ textAlign: "center" }}>
                    Form submitted successfully! Resetting form fields in 10
                    seconds...
                  </div>
                ) : (
                  <div className="career-request-form__button-container">
                    <ReCAPTCHA
                      ref={reCaptchaRef}
                      sitekey={process.env.GATSBY_recaptchaSiteKey || ""}
                      size="invisible"
                      badge="bottomright"
                    />

                    <button
                      type="submit"
                      className="btn career-request-form__submit-button"
                      onClick={() => setTouchedAttachments(true)}
                      disabled={isSubmitting}
                    >
                      <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
                    </button>
                  </div>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
